<template>
  <v-list-group v-model="isActive" no-action>
    <!-- List Item -->
    <template v-slot:activator>
      <v-list-item-icon>
        <v-avatar size="25">
          <v-img
            v-if="props.appConfig.iconType === 'image'"
            :src="props.appConfig.icon"
            :alt="props.appConfig.description"
          />
          <v-icon v-if="props.appConfig.iconType === 'icon'">{{
            props.appConfig.icon
          }}</v-icon>
        </v-avatar>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ props.appConfig.title }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <!-- Content -->
    <component v-if="isActive" :is="props.appConfig.component" />

    <div v-show="!isActive" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </v-list-group>
</template>

<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  appConfig: Object,
});

const isActive = ref(false);
</script>
