import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import { I18N_MODEL_PATH } from "../plugins/i18n";

Vue.use(Router);

const getI18NPath = (path) => {
  if (!path) return "";

  const segments = path.split("/").map((segment) => `['${segment}']`);

  return I18N_MODEL_PATH + segments.join("");
};

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login/",
      name: "Login",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("@/views/Authentication/Login"),
    },
    {
      path: "/forgotPassword",
      name: "ForgotPassword",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("@/views/Authentication/ForgotPassword/Index"),
    },
    {
      path: "/qrcodeLogin",
      name: "QrCodeLogin",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("@/views/Authentication/QrcodeLogin.vue"),
    },
    {
      path: "/reset_password",
      name: "ResetPassword",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () =>
        import("@/views/Authentication/ForgotPassword/ResetPassword/Index"),
    },
    {
      path: "/",
      name: "Cadastrar Programa de Gestão",
      meta: {
        hideMenu: false,
        i18n: {
          modelName: getI18NPath("management_program/model_name"),
        },
      },
      component: () => import("@/views/ManagementProgram/Index"),
    },
    {
      path: "/home",
      name: "Home",
      redirect: "/",
    },
    {
      path: "/program_receival_requests",
      name: "Requisições de associação pendentes",
      meta: {
        hideMenu: false,
        i18n: {
          modelName: getI18NPath("program_receival_request/model_name"),
        },
      },
      component: () => import("@/views/ProgramReceivalRequest/Index"),
    },
    // {
    //   path: "/register/program/:id",
    //   name: "Editar Programa de Gestão",
    //   meta: {
    //     hideMenu: false,
    //   },
    //   component: () => import("@/views/RegisterProgram/Edit"),
    // },
    {
      path: "/register/activity",
      name: "Cadastrar Atividade",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/RegisterActivity/Edit"),
    },
    {
      path: "/register/activity/:id",
      name: "Editar Atividade",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/RegisterActivity/Edit"),
    },
    {
      path: "/list/activity",
      name: "Atividades",
      meta: {
        hideMenu: false,
        i18n: {
          modelName: getI18NPath("activity/model_name"),
        },
      },
      component: () => import("@/views/ListActivities/List.vue"),
    },
    {
      path: "/work/new",
      name: "Novo Plano de Trabalho",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/WorkPlan/NewEdit"),
    },
    {
      path: "/management/:id/plans",
      name: "Plano de trabalho",
      meta: {
        hideMenu: false,
        i18n: {
          modelName: getI18NPath("work_plan/model_name"),
        },
      },
      component: () => import("@/views/WorkPlan/Index"),
    },
    {
      path: "/work/new/:id",
      name: "Editar Plano de Trabalho",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/WorkPlan/NewEdit"),
    },
    {
      path: "/work/state",
      name: "Status Candidatura",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/ApplicationStatus/Edit"),
    },
    // Dashboard routes
    {
      path: "/panels/activities",
      name: "Painel de Atividades",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Dashboards/Activities.vue"),
      children: [
        {
          path: "",
          meta: {
            name: "Geral",
            hideMenu: false,
          },
          component: () => import("@/views/Dashboards/GeneralComponent.vue"),
        },
        {
          path: "coordinations",
          meta: {
            name: "Coordenações",
            hideMenu: false,
          },
          component: () =>
            import("@/views/Dashboards/CoordinationComponent.vue"),
        },
        {
          path: "deliveries",
          meta: {
            name: "Entregas",
            hideMenu: false,
          },
          component: () => import("@/views/Dashboards/DeliveriesComponent.vue"),
        },
        {
          path: "members",
          meta: {
            name: "Membros",
            hideMenu: false,
          },
          component: () => import("@/views/Dashboards/MemberComponent.vue"),
        },
      ],
    },
    {
      path: "/work/result/:id",
      name: "Resumo Plano de Trabalho",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Resume/WorkPlan"),
    },
    {
      path: "/work/result/:work_plan_id/document_view",
      name: "Documentação do Plano de Trabalho",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Resume/WorkPlanAsDocument"),
    },
    {
      path: "/work_plan/:work_plan_id/activity_deliveries/:activity_id",
      name: "Resumo de Entregas",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Resume/ActivityDeliveries"),
    },
    {
      path: "/:modelName/:modelId/historico_de_mudancas",
      name: "Histórico Mudanças",
      meta: { model: "delivery", hideMenu: false },
      component: () => import("@/components/Historic.vue"),
    },
    {
      path: "/work/participation",
      name: "Definir Participação",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Participation/Edit"),
    },
    {
      path: "/register/result/:id",
      name: "Resumo Programa de Gestão",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Resume/Program"),
    },
    {
      path: "/schedule",
      name: "Cronograma",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Schedule/Schedule"),
    },
    {
      path: "/contact_list",
      name: "Agenda de Contatos",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/ContactList/index.vue"),
    },
    {
      path: "/config/institution/:id?",
      name: "Configurações da Instituição",
      meta: {
        hideMenu: false,
      },
      redirect: "/config/institution/edit",
      component: () => import("@/views/Config/Institution/InstitutionConfig"),
      children: [
        {
          path: "edit",
          name: "Editar Instituição",
          meta: {
            hideMenu: false,
          },
          component: () => import("@/views/Config/Institution/EditInstitution"),
        },
        {
          path: "custom_fields",
          meta: {
            hideMenu: false,
          },
          component: () =>
            import("@/views/Config/Institution/CustomFieldsConfig"),
        },
        {
          path: "custom_features",
          meta: {
            hideMenu: false,
          },
          component: () => import("@/views/Config/Institution/FeatureConfig"),
        },
        {
          path: "management_program_templates",
          meta: {
            hideMenu: false,
          },
          component: () =>
            import("@/views/Config/Institution/ManagementProgramTemplates"),
        },
        {
          path: "translation",
          name: "Editar Traduções",
          meta: {
            hideMenu: false,
          },
          component: () =>
            import("@/views/Config/Institution/TranslationSetting"),
        },
        {
          path: "custom_theme",
          name: "Editar Identidade Visual",
          meta: {
            hideMenu: false,
          },
          component: () =>
            import("@/views/Config/Institution/visualIdentity.vue"),
        },
        {
          path: "search_data",
          name: "Cadastro de dados de pesquisa",
          meta: {
            hideMenu: false,
          },
          component: () => import("@/views/Config/Institution/SearchData"),
        },
        {
          path: "external_smitt_integration",
          name: "Integração entre SMITTs",
          meta: {
            hideMenu: false,
          },
          component: () =>
            import("@/views/Config/Institution/ExternalSmittIntegration.vue"),
        },
        {
          path: "smitt_api",
          name: "API do SMITT",
          meta: {
            hideMenu: false,
          },
          component: () => import("@/views/Config/Institution/SmittApi.vue"),
        },
      ],
    },
    {
      path: "/config/register/institution",
      name: "Cadastrar Instituição",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/Institution/EditInstitution"),
    },
    {
      path: "/config/register/institution/:id",
      name: "Editar Registro de Instituição",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/Institution/EditInstitution"),
    },
    // {
    //   path: "/config/register/institution/:id/visualIdentity",
    //   name: "Editar Identidade Visual",
    //   meta: {
    //     hideMenu: false,
    //   },
    //   component: () => import("@/views/Config/Institution/visualIdentity.vue"),
    // },
    {
      path: "/config/register/user",
      name: "Cadastrar Usuário",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/User/User"),
    },
    {
      path: "/config/register/user/:id",
      name: "Editar Usuário",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/User/User"),
    },
    {
      path: "/config/user",
      name: "Lista de Usuário",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/User/ListUser"),
    },
    {
      path: "/config/user/proxies",
      name: "Editar Procurações do Usuário",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/User/EditUserProxies.vue"),
    },
    {
      path: "/config/coordinations",
      name: "Lista de Coordenações",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/Coordination/ListCoordination"),
    },
    {
      path: "/config/register/coordination",
      name: "Cadastrar Coordenações",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/Coordination/Coordination"),
    },
    {
      path: "/config/register/coordination/:id",
      name: "Editar Coordenação",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/Coordination/Coordination"),
    },
    {
      path: "/config/notification",
      name: "Configurar notificações",
      meta: {
        hideMenu: false,
      },
      component: () => import("@/views/Config/Notification/Notification"),
    },
  ],
});

// Global before guard to redirect user to the login page when authentication is needed
router.beforeResolve((to, _from, next) => {
  if (to.meta && to.meta.skipAuthentication) {
    next();
  } else if (store.getters["authentication/isJwtExpired"]) {
    next({ name: "Login" });
  } else {
    next();
  }
});

// // Updating page title
router.afterEach((to) => {
  if (to.name)
    document.title = `${to.name} - ${
      store.state?.visualIdentity?.visualIdentity?.name || " - SMITT"
    }`;
  else
    document.title =
      store.state?.visualIdentity?.visualIdentity?.name || " - SMITT";
});

export default router;
