<template>
  <v-menu :close-on-click="false" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="text-center">
        {{ $t($getI18NPath("nav_bar/user_modal/language")) }}
      </div>
    </template>
    <v-card>
      <v-card-title
        class="text-subtitle-2 black--text font-weight-bold text-uppercase"
      >
        {{ $t($getI18NPath("nav_bar/user_modal/translations")) }}
      </v-card-title>
      <v-card-text class="pa-0 overflow-y-auto" style="max-height: 300px">
        <v-list class="pt-0 q-pb-0 text-center">
          <v-list-item
            link
            class="black--text font-weight-medium text-capitalize"
            v-for="language in languages"
            :key="language"
            @click="changeLanguage(language)"
          >
            <v-chip
              v-if="$store?.state?.i18n?.language == language"
              outlined
              class="rounded-0"
              color="primary"
            >
              {{ $formatLanguage(language) || "Linguagem não encontrada" }}
            </v-chip>
            <div class="" v-else>
              {{ $formatLanguage(language) || "Linguagem não encontrada" }}
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    languages() {
      const { messages } = this?.$store?.state?.i18n;

      const DEFAULT_LOCATIONS = [
        "pt-BR",
        "zh-CN",
        "fr-FR",
        "es-ES",
        "en-US",
        "de-DE",
      ];

      return messages ? Object.keys(messages) : DEFAULT_LOCATIONS;
    },
  },
  methods: {
    changeLanguage(language) {
      this.$store.commit("i18n/setLanguage", language);
      this.$root.$i18n.locale = language;
    },
  },
};
</script>
