// /* eslint-disable */
import { axios } from "../../plugins/axios";

export const state = () => ({
  featureConfigs: localStorage?.featureConfigs || {},
});

export const getters = {
  getLanguage: (state) => state.language,
  getMessages: (state) => state.messages,
};

export const actions = {
  async getFeatureConfigs({ commit, rootState }) {
    const { currentUser } = rootState.authentication;

    try {
      const response = await axios.get(
        `institutions/${currentUser.institution_id}/feature_configs`,
        { params: { per_page: 900 } }
      );

      await commit("setFeatureConfigs", response?.data?.data);
    } catch (error) {
      console.warn(error);
    }
  },
};

export const mutations = {
  setFeatureConfigs(state, featureConfigs) {
    if (!featureConfigs) return;

    state.featureConfigs = featureConfigs.reduce(
      (a, feature) => ({ ...a, [feature.feature_type]: feature }),
      {}
    );

    localStorage.featureConfigs = state.featureConfigs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
