var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('ConnectionWs',{on:{"received:notification":_vm.onInsertNotification}}),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-x-transition","left":"","bottom":""},on:{"scroll":_vm.handleScroll,"input":_vm.clearNotification},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.notificationCount > 0 ? 'yellow darken-3' : '',"icon":""},on:{"click":_vm.wasViewed}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","color":"rgba(0, 0, 0, 0.2)","value":_vm.notificationCount > 250 ? 250 + ' +' : _vm.notificationCount},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"primary--text",staticStyle:{"font-size":"0.7rem"}},[_vm._v(" "+_vm._s(_vm.notificationCount > 250 ? 250 + " +" : _vm.notificationCount)+" ")])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-card',{attrs:{"min-width":"100","max-width":"350","max-height":"400"}},[_c('v-card-title',{staticClass:"text-center"},[_vm._v(" Suas notificações "),_c('br'),_c('v-btn',{attrs:{"icon":"","to":"/config/notification","color":"primary","title":"Configurar notificações de e-mails"}},[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('span',{staticClass:"grey--text caption"},[_vm._v(" Visualize em tempo real suas notificações ")])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"overflow-auto pb-0"},[_vm._l((_vm.notifications),function(notification,index){return _c('v-list-item',{key:index,staticClass:"pb-0",attrs:{"link":""}},[_c('v-list-item-icon',{staticClass:"align-self-center"},[_c('v-icon',[_vm._v("mdi-bell")])],1),_c('v-list-item-content',[(
                  notification &&
                  notification.content &&
                  notification.content.length < 250
                )?_c('div',{staticClass:"text-subtitle-2",staticStyle:{"white-space":"no-wrap"},domProps:{"innerHTML":_vm._s(
                  notification.content ||
                  'Não foi possível carregar a notificação.'
                )}}):_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"text-subtitle-2",domProps:{"innerHTML":_vm._s(
                      notification.content.slice(0, 250) + '...' ||
                      'Não foi possível carregar a notificação.'
                    )}},'div',attrs,false),on))]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(notification.content || 'Não foi possível carregar a notificação.')}})]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" Criado em "+_vm._s(_vm.formatDateTime(notification.created_at))+" ")])],1)],1)}),(_vm.loadingNotifications)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.notifications && !_vm.notifications.length)?_c('v-list-item',{staticClass:"pa-5"},[(_vm.notifications && !_vm.notifications.length)?_c('v-img-not-found',{staticClass:"mx-auto"}):_vm._e()],1):_vm._e()],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }