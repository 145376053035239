<template>
  <v-menu bottom min-width="200px" max-width="230px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-badge
        bordered
        color="error"
        dot
        overlap
        offset-x="19px"
        offset-y="19px"
        :value="showBadge"
      >
        <v-btn icon x-large v-on="on">
          <user-avatar :user="$store.state.authentication?.currentUser" :size="40" />
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <div class="mx-auto text-center">
        <user-avatar :user="$store.state.authentication?.currentUser" :size="50" />

        <h3>
          {{ $store.state.authentication?.currentUser?.name?.split(" ")?.[0] }}
        </h3>
        <p class="caption mt-1">
          {{ $store.state.authentication?.currentUser?.email }}
        </p>
        <v-divider class="mt-3"></v-divider>
        <!-- Actions -->
        <v-list-item-group
          color="primary"
          class="text-uppercase font-weight-regular subtitle-2"
        >
          <v-list-item to="/schedule">
            <v-list-item-title>{{
              $t($getI18NPath("nav_bar/user_modal/timeline"))
            }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-calendar-month-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            v-if="$isFeatureEnabled('contact_list') && $can('api_request', 'institution')"
            to="/contact_list"
          >
            <v-list-item-title>
              {{ $t('models.custom_config.feature_types.contact_list') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="$can('api_request', 'institution')"
            :to="{ name: 'Editar Instituição', params: { id: institutionId } }"
          >
            <v-list-item-title>{{
              $t($getI18NPath("nav_bar/user_modal/institution"))
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/config/user">
            <v-list-item-title>{{
              $t($getI18NPath("nav_bar/user_modal/user"))
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="$can('api_request', 'coordenination')"
            to="/config/coordinations"
          >
            <v-list-item-title>{{
              $t($getI18NPath("nav_bar/user_modal/coordination"))
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="$can('api_request', 'smitt_integration_config')">
            <v-list-item-title>
              <external-smitt-menu />
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="$isFeatureEnabled('electronic_proxies')" link>
            <v-list-item-title>
              <users-proxies-menu />
            </v-list-item-title>
          </v-list-item>

          <v-list-item  class="qr-code-item">
            <v-list-item-title>
              <auth-mobile-qrcode/>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <translate-menu />
            </v-list-item-title>
          </v-list-item>

          <v-divider class=""></v-divider>
          <v-list-item @click="exit()" color="red" class="red--text">
            <v-list-item-title>{{
              $t($getI18NPath("nav_bar/user_modal/exit"))
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </div>
    </v-card>
  </v-menu>
</template>

<script setup>
import TranslateMenu from "./TranslateMenu.vue";
import ExternalSmittMenu from "./ExternalSmittMenu.vue";
import AuthMobileQrcode from "./AuthMobileQrcode.vue";
import store from "@/store";
import router from "@/routers/router.js";
import { computed } from "vue";
import UsersProxiesMenu from "@/components/Users/UserProxiesMenu.vue";
import UserAvatar from "@/components/Users/UserAvatar.vue";

const exit = () => {
  store.dispatch("authentication/logout");
  router.push("/login");
};

const institutionId = computed(() => {
  if (store.state.externalSmittIntegration?.selectedExternalSmitt?.id) {
    return store.state.externalSmittIntegration?.selectedExternalSmitt?.metadata
      ?.id;
  }
  return store.state.authentication?.currentUser?.institution?.id;
});

const showBadge = computed(() => {
  if (store.state.externalSmittIntegration.selectedExternalSmitt?.id) return true;
  if (store.state.authentication.proxiedUser?.id) return true;

  return false;
});
</script>

<style>
@media only screen and (max-width: 600px) {
  .qr-code-item {
    display: none;
  }
}
</style>
