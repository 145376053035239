<template>
  <div>
    <v-menu v-model="open" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          class="mt-0"
          color="error"
          dot
          left
          inline
          :value="!!currentElectronProxy?.id"
        >
          <div v-bind="attrs" v-on="on" class="text-center">
            Procurações
          </div>
        </v-badge>
      </template>
  
      <v-skeleton-loader v-if="loading" type="list-item, list-item, list-item" />
      
      <v-list class="py-0" v-else>
        <v-list-item
          v-for="proxy in proxies"
          :key="proxy.id"
          @click="onProxySelected(proxy)"
        >
          <v-list-item-title>{{ proxy.user.name }}</v-list-item-title>
        </v-list-item>
  
        <v-divider></v-divider>
  
        <v-list-item v-if="currentElectronProxy?.id" @click="onProxyLogoutSelected()">
          <v-list-item-title>
            Voltar para minha conta
          </v-list-item-title>
        </v-list-item>
  
        <v-list-item v-else to="/config/user/proxies">
          <v-list-item-title>
            Minhas Procurações
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { axios } from "@/plugins/axios.js";
import store from "@/store";
import router from "@/routers/router.js";

const proxies = ref([])
const loading = ref(false)
const open    = ref(false)

const getProxies = async () => {
  if (currentElectronProxy.value?.id) return proxies.value = []

  loading.value = true
  try {
    const { data } = await axios.get(`/electronic_proxies`, {
      params: {
        per_page: 100,
        q: {
          proxy_id: store.state.authentication.currentUser.id
        },
      }
    })
    proxies.value = data.data
  } catch (error) {
    console.error("[EditUserDialog][getProxies] Request error", error)
  }
  loading.value = false
}

const onProxySelected = async (proxy) => {
  open.value = false

  try {
    await store.dispatch("authentication/loginAsProxy", proxy)

    router.push("/");
  } catch (error) {
    console.error("[UserProxiesMenu][onProxySelected] Request error", error)
  }
}

const onProxyLogoutSelected = async () => {
  open.value = false

  try {
    await store.dispatch("authentication/logoutAsProxy")

    router.push("/");
  } catch (error) {
    console.error("[UserProxiesMenu][onProxyLogoutSelected] Request error", error)
  }
}

const currentElectronProxy = computed(() => store.state.authentication.proxiedUser)

watch(open, (value) => {
  if (value) {
    getProxies()
  }
})
</script>
