<template>
  <v-dialog
    v-model="open"
    width="550"
    @click:outside="$emit('update:open', false)"
  >
    <v-card height="540">
      <CreateSupport
        :open="CREATE_SUPPORT_DIALOG"
        @update:open="CREATE_SUPPORT_DIALOG = false"
        @on:submit="UNSHIFT_OFFLINE_MODE"
      />
      <v-card-title>
        <v-row>
          <v-col cols="8">
            <span>Suporte</span>
            <br />
            <span class="text-subtitle-1 grey--text"
              >Acompanhe seus suportes</span
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn 
                  v-on="on"
                  fab
                  small
                  color="primary"
                  href="https://suporte.spezi.com.br/?invite=ouvidoria_cispar"
                  elevation="0"
                >
                  <v-icon x-large>mdi-information-symbol</v-icon>
                </v-btn>
              </template>
              <span>Ouvidoria</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-btn
              fab
              small
              color="primary"
              elevation="0"
              @click="CREATE_SUPPORT_DIALOG = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="row" style="height: 75%">
        <v-row
          :class="{
            'align-center': !SUPPORTS.length,
            'align-start': SUPPORTS.length,
          }"
        >
          <v-col class="responsive" cols="12" v-if="SUPPORTS.length">
            <v-list two-line>
              <v-list-item v-for="item in SUPPORTS" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$store.state.authentication.currentUser.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                          item && item.summary && item.summary.length > 30
                            ? item.summary.slice(0, 30) + "..."
                            : item.summary || "Sem descrição"
                        }}</span>
                      </template>
                      <span> {{ item.summary }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="FORMAT_DATE_TIME(item.created_at)"
                  ></v-list-item-action-text>
                  <v-list-item-action-text
                    :class="FORMAT_STATUS(item.support_ticket_status).color"
                    v-text="FORMAT_STATUS(item.support_ticket_status).label"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" v-else>
            <v-img-not-found class="mx-auto" width="75%" />
          </v-col>
          <v-spacer />
        </v-row>
      </v-card-text>
      <v-pagination
        v-model="PAGE"
        class=""
        :length="Math.ceil(PAGES / PER_PAGE) || 1"
        :total-visible="5"
      />
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { ref, watch, onBeforeUnmount, onMounted, computed } from "vue";
import { axios } from "../../plugins/axios";
import CreateSupport from "./Form/Index.vue";
import { state } from "../../store/modules/authentication";

const { VUE_APP_SUPPORT_JWT } = process.env;

export default {
  components: {
    CreateSupport,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data: function () {
    return {
      route: this.$route,
    };
  },
  setup() {
    var WS_CONNECTION;
    const CURRENT_USER = state()?.currentUser || null;
    const PAGE = ref(1);
    const PAGES = ref(1);
    const PER_PAGE = ref(5);
    const CREATE_SUPPORT_DIALOG = ref(false);
    const SUPPORT_JWT = VUE_APP_SUPPORT_JWT ? VUE_APP_SUPPORT_JWT : null;
    const SUPPORTS = ref([]);
    const SUPPORT_LENGTH = computed(() => SUPPORTS.value.length);
    const LAST_PING = ref(0);
    const WS_CONNECTION_STATE = ref("disconnected");
    const TWENTY_SECONDS_IN_MILLIS = 20 * 1000;
    const CHECK_PING_CONNECTION = setInterval(checkConnection, 15000);
    const SUPPORT_STATUS = ref({
      open: {
        label: "Aberto",
        color: "blue--text",
      },
      in_progress: {
        label: "Em atendimento",
        color: "orange--text",
      },
      closed: {
        label: "Finalizado",
        color: "green--text",
      },
      default: {
        label: "Pausado",
        color: "grey-text",
      },
    });

    const FORMAT_SUPPORT = (support) => {
      return {
        id: support?.id,
        demand: {
          name: support?.user?.name || "Não encontrado",
          description: support?.description || "{}",
        },
        assigned_to: support?.assigned_to || "Não atribuído",
        support_ticket_status: support.support_ticket_status,
        time: support?.time || "",
        summary: support?.summary || "Sem descrição",
        created_at: support?.created_at,
      };
    };

    const GET_SUPPORT_TICKETS = async () => {
      try {
        const RESPONSE = await axios.get(
          "https://suporte.spezi.com.br/api/v1/support_tickets",
          {
            params: {
              support_claimant_email: CURRENT_USER.email || null,
              software_jwt: SUPPORT_JWT,
              page: PAGE.value,
              per_page: 5,
            },
          }
        );

        if (RESPONSE?.data?.data && Array.isArray(RESPONSE?.data?.data)) {
          if (RESPONSE.data?.pagination_params?.page)
            PAGE.value = Number(RESPONSE.data.pagination_params.page);

          PAGES.value = RESPONSE.data.pagination_params.total || 1;
          SUPPORTS.value = RESPONSE.data.data || [];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const FORMAT_STATUS = (status) => {
      return SUPPORT_STATUS.value[status || "default"];
    };

    const FORMAT_DATE_TIME = (dateTime) => {
      if (!dateTime) return "Data não encontrada";

      const day = dateTime.slice(8, 10);
      const month = dateTime.slice(5, 7);
      const year = dateTime.slice(0, 4);
      const time = dateTime.slice(11, 16);

      return `${day}/${month}/${year} às ${time}`;
    };

    const UNSHIFT_OFFLINE_MODE = (support) => {
      if (WS_CONNECTION_STATE.value === "disconnected")
        SUPPORTS.value.unshift(FORMAT_SUPPORT(support));
    };

    function connect() {
      const WS = new WebSocket(
        `wss://suporte.spezi.com.br/api/v1/websocket/?software_jwt=${
          SUPPORT_JWT || null
        }`
      );

      WS_CONNECTION = WS;

      /**
       * Methods
       *
       * @param {*} event -> Send ws event
       */
      WS.onopen = (event) => {
        // console.log("Connected to feed room", event);

        if (WS.readyState !== WebSocket.OPEN) {
          console.log("Não foi possível estabelecer conexão com o WebSocket");
          WS.close();

          return;
        }

        WS.send(
          JSON.stringify({
            command: "subscribe",
            identifier: JSON.stringify({
              channel: "SupportChannel",
              software_jwt: SUPPORT_JWT,
            }),
          })
        );
      };

      WS.onmessage = (event) => {
        const DATA = JSON.parse(event.data);

        LAST_PING.value = new Date().getTime();

        // console.log("Received message from supports room", DATA);

        if (DATA.message) {
          const message = DATA.message.data;

          if (DATA.type === "confirm_subscription")
            WS_CONNECTION_STATE.value = "connected";

          if (
            message &&
            message.support_claimant &&
            message.support_claimant.cpf !== CURRENT_USER.cpf
          )
            return;

          switch (DATA.message.type) {
            case "save":
              if (WS_CONNECTION_STATE.value === "disconnected")
                WS_CONNECTION_STATE.value = "connected";

              var currentSupport = SUPPORTS.value.find(
                (support) => support.id === message?.id
              );

              if (currentSupport) {
                for (let i in FORMAT_SUPPORT(message)) {
                  if (currentSupport[i])
                    currentSupport[i] = FORMAT_SUPPORT(message)[i];
                }

                return;
              }

              if (WS_CONNECTION_STATE.value === "connected")
                SUPPORTS.value.unshift(FORMAT_SUPPORT(message));
              break;
            default:
              return;
          }
        }
      };

      WS.close = () => {
        WS_CONNECTION_STATE.value = "disconnected";

        if (CURRENT_USER)
          console.log("Socket is closed. Reconnect will be attempted again.");
        if (!CURRENT_USER) console.log("Socket is closed.");

        WS.send(
          JSON.stringify({
            command: "unsubscribe",
            identifier: JSON.stringify({
              channel: "SupportChannel",
              software_jwt: SUPPORT_JWT,
            }),
          })
        );
      };

      WS.onerror = function () {
        console.error("Socket encountered error.");
        WS.close();
      };
    }

    /* check if the last ping was more than 20 seconds ago */
    function checkConnection() {
      if (
        new Date().getTime() - LAST_PING.value >= TWENTY_SECONDS_IN_MILLIS &&
        CURRENT_USER?.id &&
        WS_CONNECTION_STATE === "disconnected"
      ) {
        connect();
      }
    }

    onMounted(() => {
      GET_SUPPORT_TICKETS();
      if (CURRENT_USER?.id && WS_CONNECTION_STATE.value === "disconnected")
        connect();
    });

    watch(PAGE, () => {
      GET_SUPPORT_TICKETS();
    });

    watch(SUPPORT_LENGTH, (value) => {
      if (value > 5 && value % 10) return GET_SUPPORT_TICKETS();
      if (value % 5 == 0) GET_SUPPORT_TICKETS();
    });

    onBeforeUnmount(() => {
      clearInterval(CHECK_PING_CONNECTION);

      if (WS_CONNECTION?.readyState === WebSocket.OPEN) WS_CONNECTION.close();
    });

    return {
      SUPPORTS,
      FORMAT_DATE_TIME,
      SUPPORT_STATUS,
      FORMAT_STATUS,
      PAGE,
      PAGES,
      PER_PAGE,
      CREATE_SUPPORT_DIALOG,
      UNSHIFT_OFFLINE_MODE,
    };
  },
};
</script>

<style>
@media (max-width: 600px) {
  .responsive {
    max-width: 350px;
  }
}
</style>