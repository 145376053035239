<template>
  <v-app class="v-app">
    <Navbar v-if="!hideMenu" />
    <!-- Sizes your content based upon application components -->
    <v-main class="v-main"
      :style="{ 'padding-bottom': $vuetify.breakpoint.mobile ? '56px' : '0px' }"
    >
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view :key="pageReloadKey" class="mx-md-6 mt-4"></router-view>
      </v-container>
    </v-main>
    <!-- Bottom Navigation -->
    <v-bottom-navigation
      v-if="!hideMenu && $vuetify.breakpoint.mobile"
      fixed
      grow
      color="primary"
    >
      <v-btn
        v-for="route in bottomNavidationData"
        :key="route.title"
        :to="route.path"
        style="height: unset"
      >
        <span
          v-text="$t($getI18NPath(route.i18nPath) || 'Nome não encontrado')"
        />
        <v-icon v-text="route.icon" />
      </v-btn>
    </v-bottom-navigation>

    <!-- Downloads Card -->
    <downloader-card />

    <!-- SMITT APPs -->
    <smitt-apps />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar/Index.vue";
import DownloaderCard from "@/components/FileDownload/DownloaderCard.vue";
import { mapState } from "vuex";
import SmittApps from "@/components/SmittApps/SmittApps.vue";

export default {
  components: {
    DownloaderCard,
    Navbar,
    SmittApps,
  },
  data() {
    return {
      search: "",
      dialogInfo: false,
      notifications: false,
      sound: true,
      widgets: false,
      loading: false,
      authenticationValidationPuller: null,
      bottomNavidationData: [
        {
          title: "Trabalho",
          path: "/",
          i18nPath: "management_program/model_name",
          icon: "mdi-home-city",
        },
        {
          title: "Atividades",
          path: "/list/activity",
          i18nPath: "activity/model_name",
          icon: "mdi-briefcase-check",
        },
        {
          title: "Painéis",
          path: "/panels/activities",
          i18nPath: "panel/model_name",
          icon: "mdi-chart-box",
        },
      ],
    };
  },
  methods: {
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
      }, 2000);
    },
    changeThemeColor() {
      this.$store.dispatch("visualIdentity/getVisualIdentity");
    },
  },
  computed: {
    ...mapState({
      visualIdentity: (state) => state.visualIdentity.visualIdentity,
    }),
    hideMenu() {
      return this.$route.matched.some(m => m.meta.hideMenu);
    },

    pageReloadKey() {
      return [
        this.$store.state.externalSmittIntegration?.selectedExternalSmitt?.id,
        this.$store.state.authentication?.proxiedUser?.id,
      ].join("/");
    },

    // changeTheme(){
    //   this.$vuetify.theme.themes.light.primary = this.$state.visualIdentity.visualIdentity.primary_color;
    //   this.$vuetify.theme.themes.light.secondary = this.$state.visualIdentity.visualIdentity.secondary_color;
    //   this.$vuetify.theme.themes.light.accent = this.$state.visualIdentity.visualIdentity.accent_color;

    //   return  this.$vuetify.theme.themes
    // },
  },
  mounted() {
    // document.getElementById('splash-screen').classList.add('hide-transparent')
    document.getElementById("splash-screen").remove();
    this.changeThemeColor();
  },
  beforeMount() {
    this.authenticationValidationPuller = setInterval(() => {
      this.$store.dispatch("authentication/validateSession");
    }, 1000 * 60 * 5);
  },
  beforeDestroy() {
    clearInterval(this.authenticationValidationPuller);
  },
  watch: {
    visualIdentity(val) {
      this.$vuetify.theme.themes.light.primary = val.primary_color;
      this.$vuetify.theme.themes.light.secondary = val.secondary_color;
      this.$vuetify.theme.themes.light.accent = val.accent_color;
    },
  },
};
</script>

<style>

body {
  border-top: env(safe-area-inset-top) solid #f5f5f5;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
}
.v-app {
  background-color: transparent !important;
  z-index: 1;
}
.v-main {
  background-color: transparent !important;
  z-index: 1
}
.swal2-popup {
  margin-top: 45px;
  font-family: Roboto, sans-serif !important;
}
.break-word {
  word-break: break-word;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
