import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
import i18n from "./modules/i18n.js";
import featureConfig from "./modules/featureConfig.js";
import visualIdentity from "./modules/visualIdentity.js";
import smittApps from "./modules/smittApps";
import externalSmittIntegration from "./modules/externalSmittIntegration";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    downloads: [],
  },
  mutations: {
    download(state, file) {
      state.downloads.push(file);
    },
    downloadEnded(state, path) {
      state.downloads = state.downloads.filter(
        (download) => download.path != path
      );
    },
  },
  actions: {},
  modules: {
    authentication,
    i18n,
    featureConfig,
    visualIdentity,
    smittApps,
    externalSmittIntegration,
  },
});
