<template>
  <v-skeleton-loader v-if="loading" type="sentences" width="40%" />
  <v-row class="align-center" v-else>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" class="text-h5 font-weight-bold primary--text pb-0">
          {{ title || "Título não encontrado" }}
          <v-btn
            @click="expand = !expand"
            icon
            class="mb-1 ml-1"
            v-if="textAlert && titleAlert"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>

          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="ml-2"
            :size="24"
            style="transform: translateY(-3px)"
          />
        </v-col>
        <v-col
          v-if="subtitle"
          cols="12"
          class="pt-0 grey--text text-subtitle-1 break-word"
        >
          {{ subtitle || "" }}
        </v-col>
        <v-col class="pt-0" cols="12" v-if="textAlert && titleAlert">
          <v-alert
            transition="slide-x-transition"
            v-show="expand"
            class="mx-auto secondary"
            outlined
            color="primary"
            prominent
            icon="mdi-help-circle-outline"
            border="left"
            close-icon
            text
          >
            <template #close>
              <v-btn icon @click="expand = false">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-row>
              <v-col cols="12" class="text-h6 font-weight-bold pb-0 break-word">
                {{ titleAlert || "Título não encontrado" }}
              </v-col>
              <v-col cols="12" class="pt-0 text-subtitle-1 break-word">
                {{ textAlert || "Texto não encontrado" }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => "",
    },
    subtitle: {
      type: String,
      default: null,
    },
    textAlert: {
      type: String,
      default: null,
    },
    titleAlert: {
      type: String,
      default: null,
    },
    captalize: {
      type: Boolean,
      default: false,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
};
</script>

<style></style>
