<template>
  <v-avatar :size="size" color="primary">
    <v-img v-if="user?.image_url" :src="user?.image_url">
      <template v-slot:placeholder>
        <div style="display: inline-flex; align-items: center; justify-content: center; text-align: center; width: 100%; height: 100%;">
          <span class="white--text text-h5">{{ initials }}</span>
        </div>
      </template>
    </v-img>

    <span v-else class="white--text text-h5">{{ initials }}</span>
  </v-avatar>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  size: {
    type: [Number, String],
    default: 48
  },
})

const initials = computed(() => {
  if (!props.user?.name) return ''

  return props.user.name
    .split(' ')
    .map((name) => name.charAt(0))
    .join('')
    .slice(0, 2)
    .toUpperCase()
    .trim()
})
</script>