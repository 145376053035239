import { App } from '@capacitor/app';
import router from "@/routers/router.js";
import swal from "sweetalert2";

const Capacitor = {
  install(Vue) {
    App.addListener('backButton', () => {
      if (router.currentRoute.name === 'Geral' || router.currentRoute.name === 'Login') {
        swal.fire({
          title: "Deseja fechar o aplicativo?",
          // text: "Você não poderá reabrir o aplicativo sem uma conexão com a internet.",
          showCancelButton: true,
          icon: 'question',
          confirmButtonClass: 'btn btn-warning',
          cancelButtonColor: '#8898aa',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Voltar',
        }).then((result) => {
          if (result.isConfirmed) {
            App.exitApp();
          }
        })
      } else {
        router.back();
      }
    });

    // install capacitor plugins
    Vue.prototype.$capacitor = {
      App,
    }
  }
};

export default Capacitor;
