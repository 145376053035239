// /* eslint-disable */

export const state = () => ({
  navigationDrawer: false,
  drawerWidth: 432,
});

export const getters = {};

export const actions = {};

export const mutations = {
  setNavigationDrawer(state, navigationDrawer) {
    state.navigationDrawer = navigationDrawer;
  },
  toggleNavigationDrawer(state) {
    state.navigationDrawer = !state.navigationDrawer;
  },
  setDrawerWidth(state, drawerWidth) {
    state.drawerWidth = drawerWidth;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
