var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{class:_vm.environment,staticStyle:{"z-index":"100"},attrs:{"app":"","dense":"","absolute":_vm.$vuetify.breakpoint.mobile,"height":"48"}},[_c('v-row',{staticClass:"align-center ml-2"},[_c('v-col',{staticClass:"pa-6",attrs:{"cols":"4","sm":"4","md":"2","lg":"2"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.logoPath || _vm.defaultLogo,"alt":"SMITT","contain":"","max-height":"48","max-width":"120"},on:{"click":function($event){return _vm.$router.push('/')}}})],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"cols":"4","lg":"8"}},[_c('v-row',{staticClass:"pb-5 pt-5",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"pointer text-center",class:{ selected: _vm.$route.path == '/' },style:(!(_vm.$route.path == '/')
              ? 'color: rgba(0, 0, 0, 0.5)'
              : _vm.activeNavbarColor),attrs:{"cols":"4"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$getI18NPath("nav_bar/first_item")) || "")+" ")]),_c('v-col',{staticClass:"pointer text-center",class:{ selected: _vm.$route.path == '/list/activity' },style:(!(_vm.$route.path == '/list/activity')
              ? 'color: rgba(0, 0, 0, 0.5)'
              : _vm.activeNavbarColor),attrs:{"cols":"4"},on:{"click":function($event){return _vm.$router.push('/list/activity')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$getI18NPath("nav_bar/second_item")) || "")+" ")]),_c('v-col',{staticClass:"pointer text-center",class:{ selected: _vm.$route.path == '/panels/activities' },style:(!(_vm.$route.path == '/panels/activities')
              ? 'color: rgba(0, 0, 0, 0.5)'
              : _vm.activeNavbarColor),attrs:{"cols":"4"},on:{"click":function($event){return _vm.$router.push('/panels/activities')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$getI18NPath("nav_bar/third_item")) || "")+" ")])],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"8","sm":"4","md":"3","lg":"2"}},[_c('v-row',{staticClass:"align-center"},[_c('v-spacer'),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"3","md":"2"}},[_c('Notification')],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"3","sm":"3","md":"2"}},[_c('Support',{attrs:{"open":_vm.supportDialog},on:{"update:open":function($event){_vm.supportDialog=$event}}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.supportDialog = true}}},[_c('v-icon',[_vm._v("mdi-headphones")])],1)],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"3","sm":"3","md":"2"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.commit('smittApps/toggleNavigationDrawer')}}},[_c('v-icon',[_vm._v("mdi-dots-grid")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('User')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }