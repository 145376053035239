<template>
  <v-navigation-drawer
    class="responsive-drawer"
    id="smitt-apps-navigation-drawer"
    ref="navigationDrawer"
    :value="$store.state.smittApps.navigationDrawer"
    :width="$store.state.smittApps.drawerWidth"
    stateless
    app
    right
  >
    <template v-slot:prepend>
      <div>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>SMITT APPs</v-list-item-title>
            <span class="subtitle-2 text--secondary">
              Ferramentas para te ajudar com suas tarefas
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </template>

    <v-list flat>
      <smitt-app-card
        :app-config="app"
        v-for="(app, i) in filteredApps"
        :key="i"
      />
    </v-list>

    <div id="smitt-apps-resize-bar"></div>
  </v-navigation-drawer>
</template>

<script>
import SmittAppCard from "./SmittAppCard.vue";
import ChatGPTIcon from "../../assets/icons/ChatGPT.svg";

export default {
  components: {
    SmittAppCard,
  },
  data: () => ({
    lastResize: new Date(),
    animationFrameId: null,
  }),
  // eslint-disable-next-line vue/return-in-computed-property
  mounted() {
    // add drag listener to v-navigation-drawer__border
    this.$refs.navigationDrawer.$el
      .querySelector(".v-navigation-drawer__border")
      .addEventListener("mousedown", this.startDragging);
  },
  // eslint-disable-next-line vue/return-in-computed-property
  beforeDestroy() {
    // remove drag listener to v-navigation-drawer__border
    this.$refs.navigationDrawer.$el
      .querySelector(".v-navigation-drawer__border")
      .removeEventListener("mousedown", this.startDragging);
  },
  methods: {
    startDragging(e) {
      e.preventDefault();
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.stopDragging);
    },
    drag(e) {
      e.preventDefault();

      // Throttle the number of times the method is called
      const currentTime = Date.now();
      if (currentTime - this.lastResize < 100) return;
      this.lastResize = currentTime;

      // Use requestAnimationFrame to throttle the number of times the method is called
      if (this.animationFrameId) return;

      this.animationFrameId = window.requestAnimationFrame(() => {
        const screenWidth = window.innerWidth;
        let width = screenWidth - e.clientX;

        if (width < 300) width = 300;
        if (width > window.innerWidth / 2.5) width = window.innerWidth / 2.5;

        this.$store.commit("smittApps/setDrawerWidth", width);
        this.animationFrameId = null;
      });
    },
    stopDragging(e) {
      e.preventDefault();
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopDragging);
    },
  },
  computed: {
    filteredApps() {
      return this.apps.filter((app) => !app.rules || app.rules?.());
    },
    apps() {
      return [
        {
          title: "Pesquisa LISA",
          description:
            "Ferramenta de pesquisa especializada em dados de compras governamentais, incluíndo Termos de Referência, Acórdãos e Resultados de Licitações",
          icon: "mdi-magnify",
          iconType: "icon",
          component: () => import("./Apps/LisaSearchSmittApp.vue"),
          rules: () => this.$isFeatureEnabled("lisa_search"),
        },
        {
          title: "ChatGPT",
          description: "Chatbot online de inteligência artificial",
          icon: ChatGPTIcon,
          iconType: "image",
          component: () => import("./Apps/OpenaiChatGPTSmittApp.vue"),
          rules: () => this.$isFeatureEnabled("lisa_search"),
        },
        {
          title: "Arquivos Anexados",
          description: "Arquivos anexados ao documento atual",
          icon: "mdi-file",
          iconType: "icon",
          component: () => import("./Apps/AttachFileSmittApp.vue"),
          rules: () => this.$isFeatureEnabled("lisa_search"),
        },
        {
          title: "Pesquisa Institucional",
          description:
            "Ferramente de pesquisa de dados institucionais cadastrados na plataforma",
          icon: "mdi-file-find-outline",
          iconType: "icon",
          component: () => import("./Apps/SearchInstitutionData.vue"),
          rules: () => this.$isFeatureEnabled("institution_search_data"),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
#smitt-apps-navigation-drawer {
  .v-navigation-drawer__border {
    width: 8px;
    background-color: transparent;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    cursor: ew-resize;
  }
  .v-navigation-drawer__border:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.responsive-drawer {
  @media (max-width: 600px) {
    width: 70% !important;
    margin-top: 12%;
  }
}
</style>
