<template>
  <v-menu :close-on-click="false" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        {{ $t($getI18NPath("nav_bar/user_modal/qrcode")) }}
      </div>
    </template>
    <v-card>
      <v-card-title class="text-subtitle-2 black--text font-weight-bold text-uppercase card-title-center">
        {{ $t($getI18NPath("nav_bar/user_modal/qrcode")) }}
      </v-card-title>
      <v-card-subtitle class="text-subtitle-3 text-uppercase card-title-center pb-0" style="font-size: 12px;">
        {{ $t($getI18NPath("nav_bar/user_modal/information_qrcode")) }}
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <div class="qrcode-container">
          <v-row>
            <v-col cols="6">
              <div class="pl-5">
                <h4>Instruções de Login</h4>
                <ol>
                  <li>Abra o aplicativo no seu dispositivo.</li>
                  <li>Toque na opção 'Escanear QR Code'.</li>
                  <li>Aponte a câmera para o QR Code exibido aqui.</li>
                  <li>Aguarde a confirmação no aplicativo e pronto!</li>
                </ol>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="custom-qrcode-wrapper">
                <div class="corners">
                  <div class="top left"></div>
                  <div class="top right"></div>
                  <div class="bottom right"></div>
                  <div class="bottom left"></div>
                  <qrcode-vue :value="qrcodeData" :size="180" foreground="#3f3b3b" level="L"
                    class="custom-qrcode-style" />
                </div>
              </div>
            </v-col>
          </v-row>

        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  computed: {
    userToken() {
      return this.$store.state.authentication?.currentUser?.token
    },
    userEmail() {
      return this.$store.state.authentication?.currentUser?.email
    },
    userInstitutionId() {
      return this.$store.state.authentication?.currentUser?.institution_id
    },
    userInstitutionDomain() {
      return this.$store.state.authentication?.currentUser?.institution?.domain
    },
    qrcodeData() {
      return JSON.stringify(
        {
          email: this.userEmail,
          token: this.userToken,
          institution: {
            id: this.userInstitutionId,
            domain: this.userInstitutionDomain,
          },
        }
      )
    },
  },
}

</script>

<style>
.corners {
  position: relative;
  padding: 2px;
  color: #009688
}

.top,
.bottom {
  position: absolute;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.top {
  top: 0;
  border-top: 3px solid;
}

.bottom {
  bottom: 0;
  border-bottom: 3px solid;
}

.left {
  left: 0;
  border-left: 3px solid;
}

.right {
  right: 0;
  border-right: 3px solid;
}

.custom-qrcode-wrapper {
  position: relative;
  margin: 0 auto;
  width: 80%;
  overflow: hidden;
  border: 5px solid #ffffff;
}

.custom-qrcode-style {
  margin: 0 auto;
  display: block;
  border: 15px solid #fff;
  border-radius: 25px;
}

.card-title-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-container {
  width: 600px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>