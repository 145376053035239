<template>
  <div class="text-center">
    <ConnectionWs @received:notification="onInsertNotification" />
    <v-menu
      offset-y
      transition="slide-x-transition"
      left
      bottom
      @scroll="handleScroll"
      @input="clearNotification"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="notificationCount > 0 ? 'yellow darken-3' : ''"
          v-bind="attrs"
          v-on="on"
          icon
          class=""
          @click="wasViewed"
        >
          <v-badge
            overlap
            color="rgba(0, 0, 0, 0.2)"
            :value="notificationCount > 250 ? 250 + ' +' : notificationCount"
          >
            <v-icon>mdi-bell</v-icon>
            <template #badge>
              <div class="primary--text" style="font-size: 0.7rem">
                {{ notificationCount > 250 ? 250 + " +" : notificationCount }}
              </div>
            </template>
          </v-badge>
        </v-btn>
      </template>
      <v-card min-width="100" max-width="350" max-height="400">
        <v-card-title class="text-center">
          Suas notificações <br />
          <v-btn
            icon
            class=""
            to="/config/notification" 
            color="primary"
            title="Configurar notificações de e-mails"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <span class="grey--text caption">
            Visualize em tempo real suas notificações
          </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-list class="overflow-auto pb-0">
            <v-list-item
              v-for="(notification, index) in notifications"
              :key="index"
              link
              class="pb-0"
            >
              <v-list-item-icon class="align-self-center">
                <v-icon>mdi-bell</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div
                  v-if="
                    notification &&
                    notification.content &&
                    notification.content.length < 250
                  "
                  v-html="
                    notification.content ||
                    'Não foi possível carregar a notificação.'
                  "
                  class="text-subtitle-2"
                  style="white-space: no-wrap"
                />
                <v-tooltip bottom v-else max-width="350">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="text-subtitle-2"
                      v-html="
                        notification.content.slice(0, 250) + '...' ||
                        'Não foi possível carregar a notificação.'
                      "
                    ></div>
                  </template>
                  <span v-html="notification.content || 'Não foi possível carregar a notificação.'"></span>
                </v-tooltip>
                <v-list-item-subtitle class="caption">
                  Criado em {{ formatDateTime(notification.created_at) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loadingNotifications"
            ></v-progress-linear>
            <v-list-item
              class="pa-5"
              v-if="notifications && !notifications.length"
            >
              <v-img-not-found
                class="mx-auto"
                v-if="notifications && !notifications.length"
              />
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
/* eslint-disable */
import {
  ref,
  watch,
  onBeforeUnmount,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
  computed,
} from "vue";
import ConnectionWs from "./connectionWs.vue";
import { axios } from "../../plugins/axios";
import { state } from "../../store/modules/authentication";

export default {
  props: {},
  components: {
    ConnectionWs,
  },
  setup() {
    const currentUser = state()?.currentUser || null;
    const notifications = ref([]);
    const notificationsLenght = computed(
      () => notifications?.value?.length || 0
    );
    const notificationCount = ref(0);
    const loadingNotifications = ref(false);
    const query = ref({
      page: 1,
      per_page: 6,
    });

    const getNotifications = async () => {
      loadingNotifications.value = true;

      try {
        const RESPONSE = await axios.get("notifications", {
          params: {
            user_id: currentUser.id || null,
            ...query.value,
          },
        });

        if (RESPONSE.data)
          notifications.value = [...notifications.value, ...RESPONSE.data];
      } catch (error) {
        console.log(error);
      }

      loadingNotifications.value = false;
    };

    const getNotificationCount = async () => {
      try {
        const RESPONSE = await axios.get("show_notification_count", {
          params: {
            user_id: currentUser.id,
          },
        });

        if (RESPONSE.data) {
          notificationCount.value = RESPONSE.data.count;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const wasViewed = async () => {
      // Receive notifications
      getNotifications();
    };

    const updateMultiples = async () => {
      try {
        notifications.value.forEach((notification) => {
          notification.status = "visualized";
        });

        const RESPONSE = await axios.post("update_multiple_notifications", {
          notifications: notifications.value,
        });

        if (RESPONSE.data) getNotificationCount();
      } catch (error) {
        console.log(error);
      }
    };

    const onInsertNotification = () => {
      setTimeout(() => getNotificationCount(), 500);
    };

    const handleScroll = ($event) => {
      const div = $event.srcElement;

      // reached the end of the list
      if (div.offsetHeight + div.scrollTop >= div.scrollHeight) {
        query.value.page++;

        getNotifications();
      }
    };

    const clearNotification = ($event) => {
      if (!$event) setTimeout(() => (notifications.value = []), 500);

      query.value.page = 1;
    };

    const formatDateTime = (dateTime) => {
      const date = new Date(dateTime);

      return `${date.toLocaleDateString()} às ${date.toLocaleTimeString()}`;
    };

    onBeforeMount(() => {
      getNotificationCount();
    });

    watch(notificationsLenght, () => updateMultiples());

    return {
      notifications,
      notificationsLenght,
      wasViewed,
      onInsertNotification,
      handleScroll,
      notificationCount,
      clearNotification,
      loadingNotifications,
      formatDateTime,
    };
  },
};
</script>
