<template>
  <v-img
    :width="width"
    :src="img"
    :lazy-src="img"
    alt="Imagem de dados não encontrados"
  ></v-img>
</template>

<script>
import ImgSvg from "../../assets/dataNotFound.svg";

export default {
  props: {
    width: {
      default: "20%",
    },
  },
  data() {
    return {
      img: ImgSvg,
    };
  },
};
</script>

<style></style>
