<template>
  <div style="display: none"></div>
</template>

<script>
/* eslint-disable */
import { ref, watch, onBeforeUnmount, onMounted } from "vue";
import { API_URL } from "../../plugins/axios";
import { state } from "../../store/modules/authentication";

const { NODE_ENV } = process.env;

export default {
  setup(props_, { emit }) {
    const currentUser = state()?.currentUser || null;
    const connectionStatus = ref("disconnected");
    const TWENTY_SECONDS_IN_MILLIS = 20 * 1000;
    const lastPing = ref(null);
    const CHECK_PING_CONNECTION = setInterval(checkConnection, 15000);
    var websocket;

    function connect() {
      if (connectionStatus.value === "connect") return;

      const scopeWs = new WebSocket(
        `${NODE_ENV == "development" ? "ws" : "wss"}://${
          currentUser.subdomain
        }.${API_URL.split("//")[1]}/api/v1/websocket/?access-token=${
          currentUser.token
        }&client=${currentUser.id}&uid=${currentUser.email}`
      );

      websocket = scopeWs;

      /**
       * Methods
       *
       * @param {*} event -> Send ws event
       */
      scopeWs.onopen = (event) => {
        connectionStatus.value = "connecting";

        if (scopeWs.readyState !== WebSocket.OPEN) {
          console.log("Não foi possível estabelecer conexão com o WebSocket");
          return;
        }

        scopeWs.send(
          JSON.stringify({
            command: "subscribe",
            identifier: JSON.stringify({
              channel: "NotificationChannel",
              user_id: currentUser.id,
            }),
          })
        );
      };

      scopeWs.onmessage = (event) => {
        const data = JSON.parse(event.data);

        lastPing.value = new Date();

        if (data?.type === "confirm_subscription")
          setTimeout(() => (connectionStatus.value = "connected"), 500);

        if (data.message) {
          if (connectionStatus.value === "disconnected")
            connectionStatus.value = "connected";

          switch (data.message.type) {
            case "save":
              emit("received:notification");
              break;
            default:
              return;
          }
        }
      };

      scopeWs.close = (e) => {
        if (currentUser)
          console.log("Socket is closed. Reconnect will be attempted again.");
        if (!currentUser) console.log("Socket is closed.");

        connectionStatus.value = "disconnected";

        scopeWs.send(
          JSON.stringify({
            command: "unsubscribe",
            identifier: JSON.stringify({
              channel: "NotificationChannel",
              user_id: currentUser.id,
            }),
          })
        );
      };

      scopeWs.onerror = function (err) {
        console.error("Socket encountered error.", err);
        scopeWs.close();
      };
    }

    /* check if the last ping was more than 20 seconds ago */
    function checkConnection() {
      if (
        new Date().getTime() - lastPing.value >= TWENTY_SECONDS_IN_MILLIS &&
        currentUser?.id &&
        connectionStatus === "disconnected"
      ) {
        connectionStatus.value = "disconnected";
        connect();
      }
    }

    onMounted(() => {
      connect();
    });

    onBeforeUnmount(() => {
      websocket.close();
      clearInterval(CHECK_PING_CONNECTION);
    });
  },
};
</script>
