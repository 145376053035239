import Vue from "vue";
import VueI18n from "vue-i18n";

import { state } from "../store/modules/i18n";

Vue.use(VueI18n);

const { language, messages } = state();

export const I18N_MODEL_PATH = "models";

export const loadLocaleMessages = () => {
  const formatedMessages = {};

  /* This is a workaround for Jest. */
  if (typeof require.context === "undefined") {
    return {
      "pt-BR": require("@/locales/pt-BR.json"),
      "en-US": require("@/locales/en-US.json"),
      "fr-FR": require("@/locales/fr-FR.json"),
      "zh-CN": require("@/locales/zh-CN.json"),
      "es-ES": require("@/locales/es-ES.json"),
      "de-DE": require("@/locales/de-DE.json"),
    };
  }

  const locales = requireLocales();

  /* Loading all the locales files in the locales folder. */
  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    const locale = matched ? matched[1] : null;

    if (locale) {
      formatedMessages[locale] = locales(key);
    }
  });

  return formatedMessages;
};

/**Search for .json files in the /locales folder */
const requireLocales = () => {
  return require.context("@/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
};

const dateTimeFormats = {
  "en-US": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
  },
  "pt-BR": {
    short: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
  },
};

Vue.prototype.$getI18NPath = (path) => {
  if (!path) return "";

  const segments = path.split("/").map((segment) => `['${segment}']`);

  return I18N_MODEL_PATH + segments.join("");
};

export default new VueI18n({
  dateTimeFormats,
  locale: language || "pt-BR",
  fallbackLocale: "pt-BR",
  messages: messages ? messages : loadLocaleMessages(),
});
