// /* eslint-disable */

export const state = () => ({
  selectedExternalSmitt: null,
});

export const getters = {};

export const actions = {};

export const mutations = {
  setSelectedExternalSmitt(state, externalSmitt) {
    state.selectedExternalSmitt = externalSmitt;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
