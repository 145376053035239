import Vue from "vue";
import "./config/msg";
import App from "./App.vue";
import Chart from "chart.js";
import moment from "moment";
import store from "./store";
import VueMask from "v-mask";
import ECharts from "vue-echarts";
import i18n from "./plugins/i18n";
import { use } from "echarts/core";
import Chartkick from "vue-chartkick";
import vuetify from "./plugins/vuetify";
import router from "./routers/router.js";
import { axios } from "./plugins/axios";
import { BarChart } from "echarts/charts";
import VueSweetalert2 from "vue-sweetalert2";
import { CanvasRenderer } from "echarts/renderers";
import Overlay from "./components/Overlay/Index.vue";
import ImgNotFound from "./components/Global/ImageNotFound.vue";
import TitleCard from "./components/Global/HeaderTitle.vue";
import { GridComponent, TooltipComponent } from "echarts/components";
import Capacitor from './plugins/capacitor';
import JsonEditorVue from "json-editor-vue";
use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

// register globally (or you can do it locally)
Vue.component("v-chart", ECharts);
Vue.component("v-smit-loading", Overlay);
Vue.component("v-smit-title-card", TitleCard);
Vue.component("v-img-not-found", ImgNotFound);

Vue.config.productionTip = false;

// User auth setup
// Vue.use(UserAuthentication);

// Actioncable setup
import ActioncableConnector from "./plugins/ActioncableConnector";
Vue.use(ActioncableConnector);

// Charts
Vue.use(Chartkick.use(Chart));
Vue.use(VueMask);

// capacitor
Vue.use(Capacitor);


// sweetalert2

import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

// Vue file upload component
import VueFileAgent from "vue-file-agent";
Vue.use(VueFileAgent);

Vue.use(JsonEditorVue, {});

//Import Froala Editor
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/languages/pt_br.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);

// API - HTTP requests
// Vue.use(VueAxios, {
//   secured: AxiosInstance,
//   plain:   AxiosInstance,
//   $http:   AxiosInstance,
// })

// Moment Js
Vue.use(moment);
// Date format filter
moment.locale("pt-BR");
Vue.filter("formatDate", function (value, option = null) {
  if (value) {
    if (option == "full") return moment(String(value)).format("LLLL");
    if (option == "short") return moment(String(value)).format("L");
    if (option == "time") return moment(String(value)).format("LT");
    if (option == "datetime") return moment(String(value)).format("LLL");
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
// CNPJ format filter
Vue.filter("formatCnpj", function (value) {
  if (value) {
    return String(value).replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
});

Vue.prototype.$axios = axios;
Vue.prototype.$can = function (action, controller) {
  const currentUser = this.$store.state.authentication.currentUser;

  if (!currentUser?.all_permissions?.length) return false;

  const hasTotalPermission = currentUser?.all_permissions?.some(
    (permission) => permission.subject_class === "total"
  );

  if (hasTotalPermission) return true

  if (action === "none")
    return !currentUser.all_permissions.find(
      (permission) => controller == permission.subject_class
    );

  const permission = currentUser.all_permissions.find((permission) => {
    return (
      action == "manage" ||
      action == permission.action_name ||
      controller == "total" ||
      controller == permission.subject_class
    );
  });

  return permission;
};

Vue.prototype.$formatDate = function (value) {
  if (value) {
    const [year, month, day] = value.split("-");
    return `${day}/${month}/${year}`;
  }
};

Vue.prototype.$capitalFirstLetter = (value) => {
  if (!value) return "";

  return value.charAt(0).toUpperCase() + value.slice(1);
};

Vue.prototype.$store = store;

Vue.prototype.$formatLanguage = (languageCode) => {
  const languageNames = {
    "en-US": "English (United States)",
    "pt-BR": "Português (Brasil)",
    // "zh-CN": "中文(简体) (China)",
    // "fr-FR": "Français (France)",
    "es-ES": "Español (España)",
    // "de-DE": "Deutsch (Deutschland)"
  };

  if (Object.prototype.hasOwnProperty.call(languageNames, languageCode)) {
    return languageNames[languageCode];
  } else {
    return languageCode;
  }
};

Vue.prototype.$isFeatureEnabled = function (featureType) {
  return !!this.$store.state.featureConfig?.featureConfigs?.[featureType]
    ?.enabled;
};

new Vue({
  router,
  vuetify,
  axios,
  render: (h) => h(App),
  i18n,
  store,

  data: () => ({
    currentUser: {
      name: "",
      email: "",
      token: "",
      role: {},
      subdomain: "",
      institution: {},
    },
  }),
  beforeCreate() {
    this.$store.commit("i18n/setMessages", {});
    this.$store.dispatch("i18n/getI18nLanguages");
    this.$store.dispatch("featureConfig/getFeatureConfigs");
  },
  watch: {
    "$store.state.i18n.language"(newValue) {
      this.$store.commit("i18n/setLocaleMessages", newValue);
      this.$forceUpdate();
    },
  },
}).$mount("#app");
