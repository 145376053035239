<template>
  <v-app-bar
    app
    dense
    style="z-index: 100"
    :absolute="$vuetify.breakpoint.mobile"
    height="48"
    :class="environment"
  >
    <v-row class="align-center ml-2">
      <v-col cols="4" sm="4" md="2" lg="2" class="pa-6">
        <v-img
          @click="$router.push('/')"
          :src="logoPath || defaultLogo"
          alt="SMITT"
          contain
          max-height="48"
          max-width="120"
          style="cursor: pointer"
        />
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="4" lg="8" class="">
        <v-row align="center" justify="space-around" class="pb-5 pt-5">
          <v-col
            cols="4"
            class="pointer text-center"
            :class="{ selected: $route.path == '/' }"
            @click="$router.push('/')"
            :style="
              !($route.path == '/')
                ? 'color: rgba(0, 0, 0, 0.5)'
                : activeNavbarColor
            "
          >
            {{ $t($getI18NPath("nav_bar/first_item")) || "" }}
          </v-col>
          <v-col
            cols="4"
            class="pointer text-center"
            :class="{ selected: $route.path == '/list/activity' }"
            :style="
              !($route.path == '/list/activity')
                ? 'color: rgba(0, 0, 0, 0.5)'
                : activeNavbarColor
            "
            @click="$router.push('/list/activity')"
          >
            {{ $t($getI18NPath("nav_bar/second_item")) || "" }}
          </v-col>
          <v-col
            cols="4"
            class="pointer text-center"
            :style="
              !($route.path == '/panels/activities')
                ? 'color: rgba(0, 0, 0, 0.5)'
                : activeNavbarColor
            "
            :class="{ selected: $route.path == '/panels/activities' }"
            @click="$router.push('/panels/activities')"
          >
            {{ $t($getI18NPath("nav_bar/third_item")) || "" }}
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="8" sm="4" md="3" lg="2">
        <v-row class="align-center">
          <v-spacer></v-spacer>
          <v-col cols="3" md="2" class="pr-0">
            <Notification />
          </v-col>
          <v-col cols="3" sm="3" md="2" class="pr-0">
            <Support :open.sync="supportDialog" />
            <v-btn icon @click="supportDialog = true">
              <v-icon>mdi-headphones</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" sm="3" md="2" class="pr-0">
            <v-btn
              icon
              @click="$store.commit('smittApps/toggleNavigationDrawer')"
            >
              <v-icon>mdi-dots-grid</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <User />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import User from "@/components/Navbar/UserMenu.vue";
import Support from "@/components/Support/Index.vue";
import Notification from "@/components/Notification/Index.vue";
import { mapState } from "vuex";
import defaultLogo from "@/assets/brand.svg";

export default {
  components: {
    User,
    Support,
    Notification,
  },
  data() {
    return {
      defaultLogo: defaultLogo,
      search: "",
      dialog: false,
      dialogInfo: false,
      dialogInfo2: false,
      notifications: false,
      dialogSecundary: false,
      switch1: true,
      switch2: true,
      switch3: true,
      switch4: true,
      switch5: true,
      switch6: true,
      loading: false,
      supportDialog: false,
      bottomNavidationData: [
        { title: "Trabalho", path: "/", icon: "mdi-home-city" },
        {
          title: `${this.$t(
            `${
              this.$store.state.authentication.currentUser
                ? this.$store.state.authentication.currentUser.institution
                    ?.institution_type
                : ""
            }.models.activity.model_name`
          )}s`,
          path: "/list/activity",
          icon: "mdi-briefcase-check",
        },
        { title: "Painéis", path: "/panels/activities", icon: "mdi-chart-box" },
      ],

      items: [
        {
          title: "Programa de Gestão",
          subtitle: `Ferramenta de gestão autorizada em ato normativo de Ministro de Estado e respaldada
            pela norma de procedimentos gerais, que disciplina o exercício de atividades em que
            os resultados possam ser efetivamente mensurados, cuja execução possa ser realizada
            pelos participantes.`,
        },
        {
          title: "Atividade",
          subtitle: `Conjunto de ações específicas a serem realizadas de forma individual e supervisionada
           pela chefia imediata, visando entregas no âmbito de projetos e processos de trabalho institucionais.`,
        },
        {
          title: "Entrega",
          subtitle: `Resultado do esforço empreendido na execução de uma atividade sendo definida
           no planejamento e com data prevista de conclusão.`,
        },
        {
          title: "Plano de Trabalho",
          subtitle: `<p>O plano de trabalho, que conterá:<p/>
          I - as atividades a serem desenvolvidas com as respectivas metas a serem alcançadas expressas em horas equivalentes.</br>
          <p>II - o regime de execução em que participará do programa de gestão, indicando o cronograma em que cumprirá sua jornada em regime presencial, quando for o caso.</p>
          III - o termo de ciência e responsabilidade.`,
        },
        {
          title: "Painéis",
          subtitle:
            "Resumo de Atividades, Plano de Trabalho e Relatório de Usuário.",
        },
        {
          title: "Cronograma",
          subtitle: "Calendário com o cronograma de trabalho do servidor.",
        },
      ],
      loadDinamicLogo: "",
    };
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState({
      visualIdentity: (state) => state.visualIdentity.visualIdentity,
    }),
    ...mapState({
      logoPath: (state) => state.visualIdentity.visualIdentity.thumbnail,
    }),
    searching() {
      if (!this.search) return this.items;

      const search = this.search.toLowerCase();

      return this.items.filter((item) => {
        const text = item.title.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    environment() {
      if (process.env.NODE_ENV === "development") return "development";
      if (window.location.hostname === "localhost") return "development";
      if (window.location.hostname === "smitt.dev.spezi.com.br")
        return "development";
      if (window.location.hostname === "smitt.hml.spezi.com.br")
        return "staging";

      return "production";
    },
    activeNavbarColor() {
      return (
        "border-bottom: 3px solid; border-bottom-color:" +
        this.$vuetify.theme.themes.light.primary
      );
    },
  },
};
</script>

<style scoped>
.selected {
  border-bottom: 2px solid primary;
}
.pointer {
  cursor: pointer;
}

.pointer:hover {
  background: rgba(1, 1, 1, 0.1);
}
.teste {
  height: 120px !important;
}

header.development::before {
  content: "DEV";
  background-color: #ff9800;
  position: absolute;
  transform: rotate(-90deg);
  color: white;
  width: 48px;
  left: -14px;
  top: 14px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

header.staging::before {
  content: "HOM";
  background-color: primary;
  position: absolute;
  transform: rotate(-90deg);
  color: white;
  width: 48px;
  left: -14px;
  top: 14px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}
</style>
