/* eslint-disable */
"use strict";

import axios from "axios";
import store from "../store";
import router from "@/routers/router.js";
import Vue from "vue";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const API_URL =
  process.env.NODE_ENV !== "development"
    ? process.env.VUE_APP_BACK_SERVER || "https://api.smitt.com.br/"
    : process.env.VUE_APP_BASE_URL;

const addAuthorizationToken = (data, headers) => {
  const currentUser = store.state.authentication.currentUser;

  if (currentUser && currentUser.token) {
    headers.Authorization = `Bearer ${currentUser.token}`;
  }

  if (data instanceof FormData) return data;

  return JSON.stringify(data);
};


export const config = {
  baseURL: `${API_URL}/api/v1`,
  backUrl: `${process.env.VUE_APP_BASE_URL}`,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: addAuthorizationToken,
};

const _axios = axios.create(config);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Append external smitt route if an external smitt is selected
const redirectRequestToExternalSmitt = (config) => {
  const externalSmitt =
    store.state.externalSmittIntegration.selectedExternalSmitt;

  if (externalSmitt?.id) {
    config.baseURL = `${config.baseURL}/smitt_integration_configs/${externalSmitt?.id}/api_request`;
  }

  return config;
};

const fixRequestSubdomain = (config) => {
  let currentUser = store.state.authentication.currentUser;
  let subdomain = currentUser.subdomain;
  subdomain ||= store.state.authentication.selectedSubdomain

  if (!subdomain) {
    subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;
  }

  if (!subdomain) return config;

  if (!config.baseURL.includes(`${subdomain}.`)) {
    config.baseURL = config.baseURL.replace(
      /\/\/(\w+)((?:\.\w+)*)/gi,
      `//${subdomain}.$1$2`
    );
  }

  return config;
};

// Add a request interceptor
// Do something before request is sent
_axios.interceptors.request.use(
  function (config) {
    config = redirectRequestToExternalSmitt(config);
    config = fixRequestSubdomain(config);

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response) {
      if (error?.response?.data?.validate_has_continue_permission) {
        Vue.swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "warning",
          title: "SMITT",
          timerProgressBar: true,
          text: "Por favor, finalize todos os planos de trabalhos dependentes antes de continuar",
        });
      }

      switch (error?.response?.status) {
        case 401:
          store.dispatch("authentication/logout");
          router.replace({
            path: "/",
            query: { redirect: router.currentRoute.fullPath },
          });
          break;
        case 403:
          Vue.swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: "SMITT",
            timerProgressBar: true,
            text: "Por favor, solicite permissão para acessar essa funcionalidade.",
          });
          break;
        case 404:
          // Vue.swal({
          //   toast: true,
          //   position: "top-end",
          //   showConfirmButton: false,
          //   timer: 4000,
          //   icon: "warning",
          //   title: "SMITT",
          //   timerProgressBar: true,
          //   text:
          //     "Não foi possível realizar a operação, por favor contate o suporte ou tente novamente mais tarde",
          // });
          break;
        case 500:
          Vue.swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: "SMITT",
            timerProgressBar: true,
            text: "Não foi possível realizar a operação, por favor contate o suporte ou tente novamente mais tarde",
          });
          break;
        default:
          if (error?.response?.data?.required_work_plans_ids) break;

          const keys = Object.keys(error?.response?.data);
          if (keys.length > 0) {
            const message = error?.response?.data[keys[0]][0]

            if (message) {
              Vue.swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                icon: "warning",
                title: "SMITT",
                timerProgressBar: true,
                text: message,
              });
              break;
            }
          }

          Vue.swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: "SMITT",
            timerProgressBar: true,
            text: "Não foi possível realizar a operação, por favor contate o suporte ou tente novamente mais tarde",
          });
      }
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.config.globalProperties.$axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.config.globalProperties.$axios, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

export default Plugin;

export { _axios as axios };
