<template>
  <v-menu :close-on-click="false" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        class="mt-0"
        color="error"
        dot
        left
        inline
        :value="!!currentExternalSmitt?.id"
      >
        <div v-bind="attrs" v-on="on" class="text-center">
          {{ $t("models.nav_bar.user_modal.select_external_smitt") }}
        </div>
      </v-badge>
    </template>
    <v-card>
      <v-card-title
        class="text-subtitle-2 black--text font-weight-bold text-uppercase"
      >
        {{ $t("models.nav_bar.user_modal.select_external_smitt") }}
      </v-card-title>
      <v-card-text class="pa-0 overflow-y-auto" style="max-height: 300px">
        <v-list class="pt-0 q-pb-0">
          <v-list-item-group :value="selectedSmittPosition">
            <v-list-item @click="changeExternalSmitt(null)">
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    store?.state?.authentication?.currentUser?.institution
                      ?.name || "Instância atual"
                  }}
                </v-list-item-title>

                <v-list-item-subtitle>Instância atual</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="externalSmittConfig in externalSmittConfigs"
              :key="externalSmittConfig.id"
              @click="changeExternalSmitt(externalSmittConfig)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ externalSmittConfig?.metadata?.name }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ externalSmittConfig?.host }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { axios } from "@/plugins/axios.js";
import store from "@/store";
import router from "@/routers/router.js";

const externalSmittConfigs = ref([]);
const currentExternalSmitt = computed(
  () => store.state.externalSmittIntegration.selectedExternalSmitt
);
const selectedSmittPosition = computed(() => {
  if (!currentExternalSmitt.value?.id) {
    return 0;
  }
  return (
    externalSmittConfigs.value.findIndex(
      (externalSmittConfig) =>
        externalSmittConfig?.id == currentExternalSmitt.value?.id
    ) + 1
  );
});

const changeExternalSmitt = (externalSmitt) => {
  if (externalSmitt?.id == currentExternalSmitt.value?.id) {
    store.commit("externalSmittIntegration/setSelectedExternalSmitt", null);
    router.push("/");
    return;
  }
  store.commit(
    "externalSmittIntegration/setSelectedExternalSmitt",
    externalSmitt
  );
  router.push("/");
};

const getSmittIntegrationConfigs = async () => {
  const { data } = await axios.get("smitt_integration_configs", {
    params: { per_page: 30 },
  });
  externalSmittConfigs.value = data?.data || [];
};

onMounted(async () => {
  getSmittIntegrationConfigs();
});
</script>
