// /* eslint-disable */
import { axios } from "../../plugins/axios";

import i18n from "../../plugins/i18n";
import { loadLocaleMessages } from "../../plugins/i18n";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const SUPPORTED_LANGUAGES = ["pt-BR", "en-US", "fr-FR", "zh-CN", "es-ES", "de-DE"]

export function defaultLanguage() {
  if (localStorage?.language)                           return String(localStorage.language)
  if (SUPPORTED_LANGUAGES.includes(navigator.language)) return navigator.language

  return 'pt-BR'
}

export const state = () => ({
  language: defaultLanguage(),
  messages: null,
});

export const getters = {
  getLanguage: (state) => state.language,
  getMessages: (state) => state.messages,
};

export const actions = {
  async getI18nLanguages({ state, commit, rootState }) {
    const { currentUser } = rootState.authentication;

    try {
      const response = await axios.get(
        `institutions/${currentUser.institution_id}/translation_settings`
      );

      const i18nContent = response.data?.data?.[0]?.i18n_content;

      if (i18nContent && Object.keys(i18nContent).length > 0) {
        await commit("setMessages", i18nContent);
        await commit("setLocaleMessages", state.language);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

export const mutations = {
  setLanguage(state, language) {
    if (!language) return;

    state.language = language || "pt-BR";
    localStorage.language = state.language;
  },
  setMessages(state, messages) {
    state.messages = mergeDeep(loadLocaleMessages(), messages);
  },
  setLocaleMessages(state, language) {
    const { messages } = state;
    const localeMessages = messages
      ? messages[language]
      : loadLocaleMessages()[language];

    i18n.setLocaleMessage(language, localeMessages);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
