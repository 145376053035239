import { axios } from "../../plugins/axios";
import defaultLogo from "@/assets/brand.svg";
// import vuetify from "@/plugins/vuetify";

const defaultVisualIdentity = {
  name: "SMITT",
  primary_color: "#009688",
  secondary_color: "#ECEFF1",
  accent_color: "#4DB6AC",
  thumbnail: defaultLogo,
};

const mergedDefaultVisualIdentity = () => {
  try {
    return Object.assign(
      {},
      defaultVisualIdentity,
      JSON.parse(localStorage.visualIdentity || "{}")
    );
  } catch (error) {
    console.warn("Error on merge cached visual identity with the default one");
  }

  return defaultVisualIdentity;
};

export const state = () => ({
  visualIdentity: mergedDefaultVisualIdentity(),
});

export const getters = {
  getVisualIdentity: (state) => state.visualIdentity,
};

export const actions = {
  async getVisualIdentity({ commit, rootState }) {
    const { currentUser } = rootState.authentication;

    try {
      const response = await axios.get(
        `/institutions/${currentUser.institution_id}/visual_identities`,
        { page: 1, per_page: 1 }
      );

      let data = response.data.data.at(-1);

      await commit("setVisualIdentity", data);
    } catch (error) {
      console.warn(error);
    }
  },
};

export const mutations = {
  setVisualIdentity(state, visualIdentity) {
    if (visualIdentity) {
      state.visualIdentity = Object.assign(
        {},
        defaultVisualIdentity,
        visualIdentity
      );
    } else {
      state.visualIdentity = defaultVisualIdentity;
    }

    localStorage.visualIdentity = JSON.stringify(state.visualIdentity);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
