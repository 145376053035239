<template>
  <v-list-item @click="restartDownload()">
    <v-list-item-icon>
      <v-icon v-if="failed" color="alert">mdi-close-circle</v-icon>
      <v-progress-circular
        v-else-if="progress < 100"
        :value="progress"
        :indeterminate="progress <= 0"
        color="primary"
        size="27"
      ></v-progress-circular>
      <v-icon v-else color="primary">mdi-check-circle</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="file.filename"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
      description: "Object containing path and filename to be downloaded",
    },
  },
  data: () => ({
    progress: 0,
    failed: false,
  }),
  methods: {
    async startDownload(file) {
      try {
        // Start ajax file download
        const response = await this.$axios.get(file.path, {
          responseType: "blob",
          onDownloadProgress: this.onDownloadProgress,
        });

        // Finish progress
        this.progress = 100;

        // Starting file donwload
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", file.filename);
        document.body.appendChild(link);
        link.click();

        // Trigger remove download from list
        setTimeout(() => {
          this.$store.commit("downloadEnded", this.file.path);
        }, 5000);
      } catch (error) {
        this.failed = true;
      }
    },
    onDownloadProgress(event) {
      if (event.loaded > event.total) {
        this.progress = -1;
        return;
      }
      this.progress = Math.round((event.loaded * 100) / this.file.byte_size);
    },
    restartDownload() {
      if (!this.failed) return;

      this.$store.commit("downloadEnded", this.file.path);
      this.$nextTick(() => {
        this.$store.commit("download", this.file);
      });
    },
  },
  mounted() {
    this.startDownload(this.file);
  },
};
</script>

<style></style>
