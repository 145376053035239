import { state } from "../store/modules/authentication";
import { createConsumer } from "@rails/actioncable";
import { API_URL } from "./axios";

const { NODE_ENV } = process.env;

const defaultCallback = (data) => {
  console.log("[ActioncableConnector] response:", data);
};

const ActioncableConnector = {
  install(Vue) {
    Vue.prototype.$connectToActionCable = function () {
      if (this.$cable) return;

      console.debug("[ActioncableConnector] connecting to ActionCable...");

      const currentUser = state().currentUser;
      const url = `${NODE_ENV == "development" ? "ws" : "wss"}://${
        currentUser.subdomain
      }.${API_URL.split("//")[1]}/api/v1/websocket/?access-token=${
        currentUser.token
      }&client=${currentUser.id}&uid=${currentUser.email}`;

      this.$cable = createConsumer(url);
      return this.$cable;
    };

    Vue.prototype.$disconnectFromActionCable = function () {
      if (!this.$cable) return;

      this.$cable.disconnect();
      console.debug("[ActioncableConnector] disconnected from ActionCable");
    };

    Vue.prototype.$subscribeToActionCable = function (
      channel,
      options = {},
      callback = defaultCallback
    ) {
      if (!this.$cable) {
        this.$connectToActionCable();
      }

      const params = {
        channel: channel,
        ...options,
      };
      console.debug(
        "[ActioncableConnector] subscribing to ActionCable:",
        params
      );

      return this.$cable.subscriptions.create(params, { received: callback });
    };

    Vue.prototype.$unsubscribeFromActionCable = function (subscription) {
      if (this.$cable) {
        return this.$cable.subscriptions.remove(subscription);
      }
    };
  },
};

export default ActioncableConnector;
