/* eslint-disable */
import { axios } from "../../plugins/axios";
import jwtDecode from "jwt-decode";
import Vue from "vue";
import router from "../../routers/router";

// initial state
export const state = () => ({
  authenticatedUser: localStorage.currentUser ? JSON.parse(localStorage.currentUser) : { institution: {} },
  currentUser:       localStorage.currentUser ? JSON.parse(localStorage.currentUser) : { institution: {} },
  proxiedUser:       {},
  rememberMe:        localStorage.rememberMe || false,
});

// getters
const getters = {
  jwtPayload(state) {
    if (!(state.currentUser && state.currentUser.token)) return {};

    return jwtDecode(state.currentUser.token);
  },
  isJwtExpired(_state, getters) {
    return Date.now() >= (getters.jwtPayload.exp || 0) * 1000;
  },
};

// actions
const actions = {
  validateSession: async ({ dispatch, state, getters, commit }) => {
    if (!(state.currentUser && state.currentUser.token)) {
      return;
    }
    if (getters.isJwtExpired) {
      dispatch("logout");
    } else {
      try {
        await dispatch("login", { institution: {} });
      } catch (error) {
        console.log("Session expired", error);
        commit("setCurrentUser", {
          user: {
            name: "",
            email: "",
            token: "",
            cpf: "",
            all_permissions: [],
            role: {},
            subdomain: "",
          },
        });
      }
    }
  },
  updateImage: ({ commit }, imageUrl) => {
    commit("updateUserImage", imageUrl);
  },
  login: async ({ commit }, user) => {
    try {
      const { email, password, token } = user;
      commit("setCurrentUser", { user: { institution: {} } });

      const headers = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios.post(
        "/login",
        {
          user: {
            email: email,
            password: password,
          },
        },
        {
          headers: headers,
        }
      );

      if (response.data.status === "active") {
        commit("setCurrentUser", {
          user: Object.assign({ rememberMe: user.rememberMe }, response.data),
        });
        commit("setAuthenticatedUser", {
          user: Object.assign({ rememberMe: user.rememberMe }, response.data),
        });
        commit("setProxiedUser", { user: {} });
        commit("setSelectedSubdomain", user.subdomain);
      } else {
        Vue.swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "warning",
          title: "SMITT",
          timerProgressBar: true,
          text: "Usuário inativo.",
        });
      }
    } catch (error) {
      console.error("[authentication][login]", error);
      Vue.swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        icon: "error",
        title: "Erro",
        timerProgressBar: true,
        text: "Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.",
      });
      throw error;
    }
  },
  validateSession: async ({ dispatch, state, getters, commit }) => {
    if (!(state.currentUser && state.currentUser.token)) {
      return;
    }
    if (getters.isJwtExpired) {
      dispatch("logout");
    } else {
      try {
        await dispatch("login", { token: state.currentUser.token });
      } catch (error) {
        console.log("Session expired", error);
        commit("clearAuthenticationData");
      }
    }
  },
  logout: async ({ commit }) => {
    try {
      try {
        await axios.delete("/logout");

        commit("clearAuthenticationData");
      } catch (error) {
        console.log(error);
      }
    } catch (error) { }
  },
  loginAsProxy: async ({ commit }, eletronicProxy) => {
    try {
      const response = await axios.post(`/electronic_proxies/${eletronicProxy.id}/login_as_proxy`);

      commit("setProxiedUser", { user: response.data });
      commit("setCurrentUser", { user: response.data });
    } catch (error) {
      console.log("[authentication][loginAsProxy] Error on proxy login: ", error);
    }
  },
  logoutAsProxy: async ({ commit, state }) => {
    commit("setProxiedUser", { user: {} });
    commit("setCurrentUser", { user: state.authenticatedUser });
  },
  async resetPassword({ commit }, params) {
    try {
      const RESPONSE = await axios.put("/reset_password", {
        user: params,
      });

      Vue.swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "SMITT",
        timerProgressBar: true,
        text: "Senha alterada com sucesso",
      });

      router.push({ name: "Login" });
    } catch (error) {
      console.log(error);
      Vue.swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        icon: "warning",
        title: "SMITT",
        timerProgressBar: true,
        text: "Link já foi utilizado ou não é mais válido",
      });
    }
  },
};

// mutations
const mutations = {
  setCurrentUser(state, { user }) {
    if (!user) return;

    const { rememberMe, subdomain } = user;

    state.currentUser = user || JSON.stringify({ institution: {} });

    if (rememberMe != undefined) localStorage.rememberMe = rememberMe;

    if (rememberMe) {
      localStorage.currentUser = JSON.stringify(state.currentUser);
    }

    if (subdomain) { 
      state.selectedSubdomain = subdomain;
    }
  },
  setAuthenticatedUser(state, { user }) {
    state.authenticatedUser = user;
  },
  setSelectedSubdomain(state, subdomain) {
    state.selectedSubdomain = subdomain;
  },
  setProxiedUser(state, { user }) {
    state.proxiedUser = user;
  },
  updateUserImage(state, imageUrl) {
    state.currentUser.image_url = imageUrl;
    localStorage.currentUser = JSON.stringify(state.currentUser);
  },
  clearAuthenticationData(state) {
    state.currentUser = { institution: {} };
    state.authenticatedUser = { institution: {} };
    state.proxiedUser = {};
    state.selectedSubdomain = "";
    localStorage.removeItem("currentUser");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
