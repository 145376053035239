import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import pt from "vuetify/lib/locale/pt";
import store from "../store";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary:
          store.state?.visualIdentity?.visualIdentity?.primary_color ||
          colors.teal,
        secondary:
          store.state?.visualIdentity?.visualIdentity?.secondary_color ||
          colors.grey.lighten5,
        accent:
          store.state?.visualIdentity?.visualIdentity?.accent_color ||
          colors.teal.lighten2,
        error: colors.red,
      },
    },
  },
});
