<template>
  <v-dialog v-model="open" width="550" persistent>
    <v-card height="540">
      <v-card-title>
        <div class="">
          <span>Novo suporte</span>
          <br />
          <span class="text-subtitle-1 grey--text">
            Solicitação de suporte
          </span>
        </div>
      </v-card-title>
      <v-form ref="NEW_SUPPORT_FORM" v-model="VALID_FORM" lazy-validation>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="FORM.support_claimant.email"
                outlined
                label="E-mail"
                :rules="[RULES.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-text-field
                v-model="FORM.support_claimant.phone"
                outlined
                label="Telefone"
                :rules="[RULES.required]"
                v-mask="'(##) # ####-####'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-textarea
                v-model="FORM.summary"
                outlined
                label="Descrição"
                :rules="[RULES.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="ON_CLOSE">Cancelar</v-btn>
          <v-btn text color="primary" width="100" @click="ON_SUBMIT"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import {
  ref,
  onBeforeMount,
  getCurrentInstance,
  onMounted,
  process,
} from "vue";
import { axios } from "../../../plugins/axios";

const { VUE_APP_SUPPORT_JWT } = process.env;

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data: function () {
    return {
      route: this.$route,
    };
  },
  setup(props, { emit }) {
    const USER = JSON.parse(localStorage.getItem("currentUser"));
    const VALID_FORM = ref(true);
    const FORM = ref({
      support_claimant: {
        name: null,
        email: null,
        phone: null,
        location: null,
      },
      summary: null,
      description: {},
    });
    const MARKDOWN = ref(null);
    const NEW_SUPPORT_FORM = ref(null);
    const RULES = ref({
      required: (v) => !!v || "Campo obrigatório",
    });
    const SUPPORT_JWT = VUE_APP_SUPPORT_JWT ? VUE_APP_SUPPORT_JWT : null;

    const ON_CLOSE = () => {
      emit("update:open", false);

      if (NEW_SUPPORT_FORM.value) NEW_SUPPORT_FORM.value.reset();
    };

    const ON_SUBMIT = async () => {
      if (NEW_SUPPORT_FORM.value.validate()) {
        try {
          var mk = "";

          FORM.value.support_claimant.location = window.location.pathname
            .replace("/", "")
            .split("/")[0];

          MARKDOWN.value = Object.assign(MARKDOWN.value ? MARKDOWN.value : {}, {
            Sumário: FORM.value.summary || "",
          });

          /* It's a for loop that iterates over the keys of the object MARKDOWN */
          for (let i in MARKDOWN.value) {
            // if (Array.isArray(MARKDOWN.value[i])) {
            //   MARKDOWN.value[i] = MARKDOWN.value[i].map((e) => {
            //     return `\n\n> **Nome**: ${e.name ? e.name : "Não encontrado"}`;
            //   });
            // }

            mk = mk + `**${i}:** ${MARKDOWN.value[i] || "Não definido"}\n\n`;
          }

          /* Added markdown value */
          FORM.value.description = {
            type: "markdown",
            text: mk,
          };

          const RESPONSE = await axios.post(
            `https://suporte.spezi.com.br/api/v1/support_tickets?software_jwt=${SUPPORT_JWT}`,
            {
              support_ticket: FORM.value,
            }
          );

          FORM.value.summary = "";
          NEW_SUPPORT_FORM.value.resetValidation();
          MARKDOWN.value = {};

          if (RESPONSE.data) emit("on:submit", RESPONSE.data);
        } catch (error) {
          console.log(error);
        }

        emit("update:open", false);
      }
    };

    const GET_MANAGEMENT_PROGRAM = async (id) => {
      try {
        const RESPONSE = await axios.get("/management_programs/" + id);

        if (RESPONSE?.data) {
          MARKDOWN.value = {
            "Id da instituição": RESPONSE.data.id,
            "Nome da instituição": RESPONSE.data.title || "",
            "Nome da coordenação": RESPONSE.data.coordination_name || "",
          };
        }
      } catch (error) {
        console.log(error);
      }
    };

    onBeforeMount(() => {
      if (getCurrentInstance().data?.route?.path.includes("management"))
        GET_MANAGEMENT_PROGRAM(getCurrentInstance().data.route.params.id);
    });

    onMounted(() => {
      FORM.value.support_claimant = {
        name: USER.name || "",
        email: USER.email || "",
        phone: USER.celular || USER.telefone || "",
        cpf: USER.cpf || "",
      };
    });

    return {
      NEW_SUPPORT_FORM,
      VALID_FORM,
      FORM,
      ON_CLOSE,
      ON_SUBMIT,
      RULES,
    };
  },
};
</script>
