<template>
  <v-card
    class="float-right"
    id="download-card"
    elevation="1"
    v-show="downloads.length"
  >
    <v-card-actions @click="show = !show">
      <span style="padding-left: 10px"> Downloads </span>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item-group color="primary">
            <download-item
              v-for="download in downloads"
              :key="download.path"
              :file="download"
            />
          </v-list-item-group>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import DownloadItem from "./DownloadItem.vue";
export default {
  components: {
    DownloadItem,
  },
  data: () => ({
    show: true,
  }),
  computed: {
    downloads() {
      return this.$store.state.downloads;
    },
  },
  watch: {
    downloads(val) {
      console.log("downloads changed -> ", val);
    },
  },
};
</script>

<style scoped>
#download-card {
  position: fixed;
  right: 20px;
  bottom: 5px;
  max-width: 400px;
}
</style>
