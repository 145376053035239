<template>
  <v-overlay :value="loading">
    <v-progress-circular
      color="primary"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>

<style></style>
